<template>
	<div class="page">
		<!-- 搜索栏 -->
		<div class="tabView">
			<div class="tabViewL">
				<div class="search">
					<el-input v-model="keyword" @keyup.enter.native="toSearch" placeholder="请输入关键字"
						style="width: 150px;margin-right: 10px;">
					</el-input>
					<el-button type="primary" style="margin-right: 22px;" @click="toSearch">查询</el-button>
				</div>
				<div class="search">
					<div class="searchTitle">订单状态</div>
					<el-select v-model="orderState" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="(item, index) in stateList" :key="index" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</div>
				<div class="search">
					<div class="searchTitle">结算方式</div>
					<el-select v-model="orderPayType" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="(item, index) in paymentList" :key="index" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</div>
				<div class="search">
					<div class="searchTitle">结算时间</div>
					<el-date-picker v-model="daterange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDate">
				</el-date-picker>
				</div>
				<div class="search" v-if="nickName">
					<el-tag closable  @close="handleClose()">{{ nickName}}</el-tag>
				</div>
			</div>
			<div class="tabViewR">
				<el-button @click="reset">重置</el-button>
				<el-button v-if="!$public.isNull($route.query.str)" @click="$router.back()">返回</el-button>
			</div>
		</div>
		<!-- 内容信息 -->
		<div class="view">
			<el-table :data="tableData" height="100%">
				<el-table-column min-width="120" label="订单号">
					<template slot-scope="scope">
						{{ scope.row.refundNo }}
					</template>
				</el-table-column>
				<el-table-column min-width="120" label="原订单号">
					<template slot-scope="scope">
						{{ scope.row.orderNo }}
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="顾客">
					<template slot-scope="scope">
						{{ scope.row.userInfo && scope.row.userInfo.userName }}	
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="手机号">
					<template slot-scope="scope">
						{{ scope.row.userInfo && scope.row.userInfo.userMobile }}
					</template>
				</el-table-column>
				<el-table-column min-width="90" label="结算金额">
					<template slot-scope="scope">
						{{ scope.row.totalPrice }}
					</template>
				</el-table-column>
				<el-table-column min-width="80" label="订单状态">
					<template slot-scope="scope">
						{{ $public.getMatchingItem(stateList, 'value', scope.row.orderState).label }}
					</template>
				</el-table-column>
				<el-table-column min-width="120" label="结算方式">
					<template slot-scope="scope">
						<div v-if="!$public.isNull(scope.row.payInfo)">
							<div v-for="(item, index) in scope.row.payInfo.payList" :key="index">
								<span>
									{{ $public.getMatchingItem(paymentList, 'value', item.payType).label }}
									{{ item.money }}
								</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column min-width="70" label="订单备注">
					<template slot-scope="scope">
						{{ scope.row.remark }}
					</template>
				</el-table-column>
				<el-table-column min-width="70" label="结算备注">
					<template slot-scope="scope">
						<div v-if="!$public.isNull(scope.row.payInfo)">
							{{ scope.row.payInfo.payRemark }}
						</div>
					</template>
				</el-table-column>
				<el-table-column min-width="100" label="创建人">
					<template slot-scope="scope">
						{{ scope.row.operationInfo.create.handleUserName }}
					</template>
				</el-table-column>
				<el-table-column min-width="140" label="销售日期">
					<template slot-scope="scope">
						{{ $public.FTime(scope.row.operationInfo.create.handleTimeStamp, "YYYY-MM-DD HH:mm") }}
					</template>
				</el-table-column>
				<el-table-column min-width="130" label="操作">
					<template slot-scope="scope">
						<el-button type="text" size="small" v-if="$buttonAuthority('order-returnOrderList-see')"
							@click="$router.push({ path: '/order/returnOrderSee?no=' + scope.row.refundNo })">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="width:100%;position: absolute;bottom: 0;right: 0;">
				<page :pageIndex="page.pageIndex" :pageSizes="pageSizes"
					@size-change="handleSizeChange" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
			</div>
		</div>
	</div>
</template>
<script>
import page from "../../../components/page.vue"
export default {
	components: { page },
	data() {
		return {
			// 搜索
			keyword: '',
			orderState: -1,
			orderPayType: -1,
			// table
			page: {
				pageIndex: 1,
				pageSize: 10,
				total: 0,
			},
			pageSizes: [10, 15,30,60],
			tableData: [],
			daterange:[],
			nickName:'',
		}
	},
	computed: {
		stateList() {
			let list = this.$public.getReturnOrderStateList()
			list.unshift({ label: "全部", value: -1 })
			return list
		},
		paymentList() {
			let list = this.$public.getPaymentList()
			list.unshift({ label: "全部", value: -1 })
			return list
		},
	},
	mounted() {
		this.$nextTick(() => {
			if (!this.$public.isNull(this.$route.query.str)) {
				this.userId = this.$route.query.str
			this.nickName=this.$route.query.nickName
				// this.toSearch()
			}
			this.getTableData();
		})
	},
	methods: {
		// 时间筛选
		changeDate(val){
			if(val){
				let time1 = this.$public.FTimeStr10(val[0])
				let time2 = this.$public.FTimeStr10(val[1])
				this.getTableData(time1,time2)
			}
				
			},
		// 执行搜索
		toSearch() {
			this.page.pageIndex = 1;
			this.getTableData()
		},
		handleClose(){
			this.keyword = ''
			this.userId = ''
			this.nickName = ''
			this.orderState = -1
			this.orderPayType = -1
			this.page.pageIndex = 1;
			this.daterange = [];
			this.getTableData()
		},
		handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
		reset() {
			this.keyword = ''
			this.orderState = -1
			this.orderPayType = -1
			this.page.pageIndex = 1;
			this.daterange=[]
			this.getTableData()
		},
		changePage(num) {
			this.page.pageIndex = num;
			this.getTableData()
		},
		getTableData(time1,time2) {
			let params = {
				"orderState": this.orderState === -1 ? [] : [this.orderState],  // 订单状态
				"orderPayType": this.orderPayType === -1 ? [] : [this.orderPayType],  // 支付方式
				"keyword": this.keyword,
				"pageIndex": this.page.pageIndex,
				"pageSize": this.page.pageSize,
				"payStartTime":time1,
				"payEndTime":time2,
				"userId":this.userId,
				"shopId": this.$store.state.userInfo.nowShop.id,  // 店铺编号
				"companyId": this.$store.state.userInfo.companyInfo.id,  // 公司编号

			}
			let loading = this.$loading()
			this.$http.post("/order/OrderRefund/GetList", params).then(res => {
				loading.close()
				if (res.code === 0) {
					this.tableData = res.data.list
					this.page.total = res.data.count
				}
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.page {
	width: 100%;
	height: 100%;
	color: $fontColor;

	// 搜索信息
	.tabView {
		height: 80px;
		border-radius: 20px;
		background: #fff;
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 20px;
		box-sizing: border-box;

		.tabViewL {
			.search {
				margin-right: 12px;
				display: inline-flex;
				padding: 20px 0;

				.searchTitle {
					font-size: 14px;
					width: 60px;
					color: $fontColor;
					line-height: 40px;
				}
			}
		}

		.tabViewR {
			padding-top: 20px;
		}
	}

	// 内容信息
	.view {
		position: relative;
		height: calc(100% - 96px);
		padding-bottom: 52px;
		box-sizing: border-box;
		width: 100%;
		border-radius: 20px;
		overflow: hidden;
	}
}
</style>
